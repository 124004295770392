<template>
  <b-row class="match-height">
    <b-col lg="12">
      <b-card title="Purchase Order">
        <!-- Modal Reference History-->
        <b-modal
          id="modal-reference-history"
          v-model="modalReferenceHistoryShow"
          centered
          hide-footer
          title="References"
        >
          <div v-if="references.length > 0">
            <b-row>
              <b-col cols="3" class="text-center font-weight-bold">
                Link
              </b-col>
              <b-col cols="3" class="text-center font-weight-bold">
                Supplier
              </b-col>
              <b-col cols="2" class="text-center font-weight-bold">
                Price
              </b-col>
              <b-col cols="2" class="text-center font-weight-bold">
                Branch
              </b-col>
              <b-col cols="2" class="text-center font-weight-bold">
                Option
              </b-col>
            </b-row>
            <b-row
              v-for="reference in references"
              :key="reference.item_reference_id"
              class="border"
            >
              <b-col cols="3 text-center border-table-body">
                <a :href="reference.item_reference_link">{{
                  reference.item_reference_link
                }}</a>
              </b-col>
              <b-col cols="3 text-center border-table-body">
                {{ reference.item_reference_supplier }}
              </b-col>
              <b-col cols="2 text-center border-table-body">
                {{ reference.currency.currency_symbol }}
                {{ reference.item_reference_price }}
              </b-col>
              <b-col cols="2 text-center border-table-body">
                {{ reference.item_reference_branch_code }}
              </b-col>
              <b-col cols="2 text-center border-table-body">
                <b-button
                  type="button"
                  variant="primary"
                  class="btn btn-gradient-primary"
                  @click="
                    choose(
                      reference.item_reference_id,
                      reference.item_reference_item_id,
                      reference.item_reference_price
                    )
                  "
                >
                  <feather-icon
                    icon="CheckIcon"
                    size="16"
                    class="align-middle"
                  />
                </b-button>
              </b-col>
              <hr />
            </b-row>
          </div>
          <div v-if="references.length == 0">
            <p class="text-center">
              No Data
            </p>
          </div>
        </b-modal>
        <!-- Modal Reference-->
        <b-modal
          id="modal-reference"
          v-model="modalReferenceShow"
          centered
          hide-footer
          title="Add Reference"
        >
          <b-form-group>
            <label for="email">Supplier :</label>
            <input
              v-model="itemSupplier"
              type="text"
              name="supplier"
              class="form-control"
            />
          </b-form-group>
          <b-form-group>
            <input
              v-model="itemReference"
              type="hidden"
              name="itemId"
              class="form-control"
            />
            <label for="email">Note/Link :</label>
            <input
              v-model="itemLink"
              type="text"
              name="itemLink"
              class="form-control"
            />
          </b-form-group>
          <b-form-group>
            <label for="email">Price :</label>
            <input
              v-model="itemPrice"
              type="number"
              name="itemPrice"
              class="form-control"
            />
          </b-form-group>
          <b-form-group>
            <label for="no_invoice">Currency *</label>
            <v-select-new v-model="itemCurrency" :options="currencies" />
          </b-form-group>
          <b-form-group>
            <label for="no_invoice">Description :</label>
            <input
              v-model="itemDescription"
              type="text"
              name="itemDescription"
              class="form-control"
            />
          </b-form-group>
          <b-form-group>
            <input
              type="button"
              class="btn btn-primary pull-right"
              value="Save"
              @click="saveNewReference()"
            />
          </b-form-group>
        </b-modal>
        <b-form @keyup.enter.prevent="" @submit.prevent="submitNewPurchaseOrder">
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Supplier *"
                label-for="supplier"
                label-cols-md="12"
              >
                <v-select-new v-model="chosenContact" :options="contacts" />
              </b-form-group>
              <b-form-group
                label="Shipment By *"
                label-for="purchase_order_shipment_by"
                label-cols-md="12"
              >
                <b-form-input
                  id="purchase_order_shipment_by"
                  v-model="shipmentBy"
                  placeholder="Shipment By"
                  required="required"
                />
              </b-form-group>
              <b-form-group
                label="Delivery Address *"
                label-for="purchase_order_delivery_address"
                label-cols-md="12"
              >
                <b-form-input
                  id="purchase_order_delivery_address"
                  v-model="deliveryAddress"
                  placeholder="Delivery Address"
                  required="required"
                />
              </b-form-group>
              <b-form-group
                label="Delivery Schedule *"
                label-for="purchase_order_delivery_schedule"
                label-cols-md="12"
              >
                <b-form-datepicker v-model="deliverySchedule" />
              </b-form-group>

              <b-form-group
                label="Purchase Order Type *"
                label-for="purchase_order_type"
                label-cols-md="12"
              >
                <v-select-new v-model="chosenType" :options="types" />
              </b-form-group>

              <b-form-group
                label="With PPN *"
                label-for="ppn"
                label-cols-md="12"
              >
                <v-select-new v-model="chosenPpn" :options="ppnOptions" />
              </b-form-group>
              <b-form-group
                label="Billing Address *"
                label-for="billing_address"
                label-cols-md="12"
              >
                <v-select-new v-model="chosenBranch" :options="branches" />
              </b-form-group>
              <b-form-group
                label="Term Of Payment (days)*."
                label-for="term_of_payment"
                label-cols-md="12"
              >
                <b-form-input
                  id="term_of_payment"
                  v-model="termOfPayment"
                  type="number"
                  placeholder="Term Of Payment"
                  required="required"
                />
              </b-form-group>
              <b-form-group
                label="Ref No"
                label-for="ref_no"
                label-cols-md="12"
                v-if="chosenType == 'Head Office'"
              >
                <b-form-input
                  id="ref_no"
                  v-model="purchaseOrderRefNo"
                  placeholder="Ref NO"
                />
              </b-form-group>
              <b-form-group
                label="Payment Type *"
                label-for="payment_type"
                label-cols-md="12"
              >
                <v-select-new
                  v-model="chosenPaymentType"
                  :options="paymentTypes"
                />
              </b-form-group>
              <b-form-group
                label="Discount Percentage (%)"
                label-for="purchase_order_discount_percentage"
                label-cols-md="12"
              >
                <b-form-input
                  id="purchase_order_discount_percentage"
                  v-model.number="purchaseOrderDiscountPercentage"
                  type="number"
                  placeholder="Discount Percentage"
                />
              </b-form-group>
              <b-form-group
                label="Transport Fee"
                label-for="purchase_order_transport_fee"
                label-cols-md="12"
              >
                <b-form-input
                  id="purchase_order_transport_fee"
                  v-model.number="transportFee"
                  type="number"
                  pattern="[0-9]*"
                  placeholder="Transport Fee"
                />
              </b-form-group>
              <b-form-group
                label="Link/Note"
                label-for="purchase_order_link"
                label-cols-md="12"
              >
                <b-form-input
                  id="purchase_order_link"
                  v-model="purchaseOrderLink"
                  placeholder="Link"
                />
              </b-form-group>
            <b-form-group>
              <label for="no_invoice">Currency :</label>
              <v-select-new v-model="chosenCurrency" :options="currencies" />
            </b-form-group>
              <b-form-group label="Purchase Request *" label-for="chosenPR">
                <v-select-new
                  v-model="chosenPR"
                  :filterable="true"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="items"
                  :get-option-label="getOptionLabel"
                  :get-option-key="getOptionKey"
                  @input="putItem"
                  @search="inputSearch"
                />
              </b-form-group>
              <b-row>
                <b-col cols="4" class="text-center font-weight-bold">
                  Item name
                </b-col>
                <b-col cols="2" class="text-center font-weight-bold">
                  Item QTY
                </b-col>
                <b-col cols="2" class="text-center font-weight-bold">
                  Price
                </b-col>
                <b-col cols="2" class="text-center font-weight-bold">
                  Cheapest Price
                </b-col>
                <b-col cols="2" class="text-center font-weight-bold">
                  Actions
                </b-col>
              </b-row>
              <p />
              <b-row v-for="chosen in chosens" :key="chosen.value">
                <br />
                <b-col cols="4 text-center">
                  <b-list-group>
                    <b-list-group-item href="#">
                      {{ chosen.label }}
                    </b-list-group-item>
                  </b-list-group>
                </b-col>
                <b-col cols="2 text-center">
                  <b-form-input
      type="number"
      v-model.number="chosen.qty"
      :max="chosen.max"
      @input="checkMax(chosen)"
      min="0"
    />
                </b-col>
                <b-col cols="2 text-center">
                  <b-form-input v-model="chosen.price" :max="chosen.price" />
                </b-col>
                <b-col cols="2 text-center">
                  <b-form-input
                    v-model="chosen.cheapest"
                    placeholder="Cheapest"
                    readonly
                  />
                </b-col>
                <b-col cols="2 text-center">
                  <b-button
                    size="sm"
                    type="button"
                    variant="primary"
                    class="mr-1"
                    @click="addReference(chosen.value)"
                  >
                    <feather-icon icon="PlusIcon" size="18" />
                  </b-button>
                  <b-button
                    size="sm"
                    type="button"
                    variant="primary"
                    class="mr-1"
                    @click="showReference(chosen.value)"
                  >
                    <feather-icon icon="EyeIcon" size="18" />
                  </b-button>
                  <b-button
                    size="sm"
                    type="button"
                    variant="danger"
                    class="mr-1"
                    @click="deleteItem(chosen)"
                  >
                    <feather-icon icon="TrashIcon" size="18" />
                  </b-button>
                </b-col>
                <b-col cols="12">
                  <br />
                </b-col>
              </b-row>
            </b-col>
            <!-- submit and reset -->
            <b-col offset-md="12">
              <b-button type="submit" variant="primary" class="mr-1"
                :disabled="submitButtonDisabled">
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BCard,
  BListGroup,
  BListGroupItem,
  BFormDatepicker,
  BFormFile,
} from "bootstrap-vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import axios from "axios"
import moment from "moment"
import { getUserData } from '@/auth/utils'
import Ripple from "vue-ripple-directive"

import { ref } from "@vue/composition-api"

const VersionCheck = ""
const file = ""
const items = []
const chosenPR = ref()
const chosens = []
const shipmentTo = ""
const itemId = ""
const purchaseOrderRefNo = ""
const purchaseOrderProject = ""
const purchaseOrderCostCenter = ""
const purchaseOrderLink = ""
const purchaseOrderQtyRequested = 0
const transportFee = 0
const purchaseOrderStatus = ""
const purchaseOrderNotes = ""
const purchaseOrderDiscountPercentage = ""
const deliverySchedule = ""
const shipmentBy = ""
const modalReferenceShow = false
const itemReference = ""
const itemLink = ""
const itemPrice = ""
const itemDescription = ""
const itemSupplier = ""
const contacts = []
const branches = []
const references = []
const currencies = []
const itemsUnfiltered = []
const types = ["Head Office", "Cabang"]
const chosenType = "Cabang"
const chosenBranch = ""
const chosenContact = ""
const chosenCurrency = ""
const termOfPayment = "30"
const billingAddress = ""
const deliveryAddress = ""
const itemCurrency = ""
const paymentTypes = ["Credit", "Cash", "COD"]
const ppnOptions = ["Yes", "No"]
const chosenPpn = ""
const chosenPaymentType = ""
const modalReferenceHistoryShow = false
const submitButtonDisabled = false

export default {
  components: {
    BFormFile,
    BFormDatepicker,
    BListGroup,
    BListGroupItem,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      chosenCurrency,
      transportFee,
      chosenPpn,
      ppnOptions,
      itemsUnfiltered,
      submitButtonDisabled,
      shipmentBy,
      purchaseOrderDiscountPercentage,
      chosenType,
      types,
      purchaseOrderLink,
      itemSupplier,
      paymentTypes,
      chosenPaymentType,
      itemCurrency,
      currencies,
      deliveryAddress,
      chosenBranch,
      branches,
      termOfPayment,
      billingAddress,
      modalReferenceHistoryShow,
      chosenContact,
      contacts,
      itemId,
      deliverySchedule,
      file,
      modalReferenceShow,
      itemReference,
      itemLink,
      itemPrice,
      itemDescription,
      references,
      VersionCheck,
      items,
      chosenPR,
      chosens,
      shipmentTo,
      purchaseOrderRefNo,
      purchaseOrderProject,
      purchaseOrderCostCenter,
      purchaseOrderQtyRequested,
      purchaseOrderStatus,
      purchaseOrderNotes,
    }
  },
  created() {
    window.setInterval(() => {
      this.removeReadOnly()
    }, 1000)
  },
  mounted() {
    this.getPR()
    this.getContacts()
    this.getBranches()
    this.getCurrencies()
  },
  watch: {
    async chosenType(newValue) {
      if (newValue === "Cabang") {
        this.chosens = []
        this.items = this.itemsUnfiltered.filter(item => item.is_center === false)
      } else {
        this.chosens = []
        this.items = this.itemsUnfiltered.filter(item => item.is_center === true)
      }
    },
  },
  methods: {
    checkMax(chosen) {
      const qty = parseInt(chosen.qty) || 0
      const max = parseInt(chosen.max) || 0
      if (qty > max) {
        chosen.qty = chosen.max
        this.$set(chosen, 'qty', chosen.max)
      }
    },
    deleteItem(item) {
      const index = this.chosens.findIndex(chosen => chosen.id === item.id)
      if (index >= 0) {
        this.chosens.splice(index, 1)
      }
    },
    removeReadOnly() {
      const searches = document.getElementsByClassName("vs__search")
      for (let i = 0; i < searches.length; i += 1) {
        searches[i].removeAttribute("readonly")
      }
    },
    getCurrencies() {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      axios
        .get(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_CURRENCIES}`,
          { headers },
        )
        .then(response => {
          if (response.data.success === true) {
            const itemsJoin = []
            response.data.data.map(elem => {
              itemsJoin.push({
                label: `${elem.currency_full_name} (${elem.currency_symbol})`,
                value: elem.currency_id,
              })
            })
            this.currencies = itemsJoin
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Get Currencies Failed",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
        .catch(e => {
          console.log(e)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Get Branches Failed",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          })
        })
    },
    getBranches() {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      axios
        .get(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_BRANCHES}`,
          { headers },
        )
        .then(response => {
          if (response.data.success === true) {
            const itemsJoin = []
            const userData = this.$cookies.get('userData')
            const userBranch = userData.branch
            response.data.data.map(elem => {
              if (userBranch !== null && typeof userBranch !== 'undefined') {
                if (elem.branch_code === userBranch.branch_code) {
                  itemsJoin.push({
                    label: `${elem.branch_name} (${elem.branch_code})`,
                    value: elem.branch_id,
                  })
                }
              } else {
                itemsJoin.push({
                  label: `${elem.branch_name} (${elem.branch_code})`,
                  value: elem.branch_id,
                })
              }
            })
            this.branches = itemsJoin
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Get Branches Failed",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
        .catch(e => {
          console.log(e)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Get Branches Failed",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          })
        })
    },
    getContacts() {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      axios
        .get(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_CONTACTS}`,
          { headers },
        )
        .then(response => {
          if (response.data.success === true) {
            const itemsJoin = []
            response.data.data.map(elem => {
              itemsJoin.push({
                label: `${elem.contact_name} (${elem.contact_city})`,
                value: elem.contact_id,
              })
            })
            this.contacts = itemsJoin
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Get Contacts Failed",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
        .catch(e => {
          console.log(e)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Get Contacts Failed",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          })
        })
    },
    getPR() {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      axios
        .get(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PURCHASE_REQUESTS}?is_confirmed=true&is_finished=false`,
          { headers },
        )
        .then(response => {
          if (response.data.success === true) {
            const itemsJoin = []
            response.data.data.map(elem => {
              const userData = getUserData()
              const userBranch = userData.branch
              if (userBranch !== null) {
                if (elem.purchase_request_number.includes(userBranch.branch_code)) {
                itemsJoin.push({
                  is_center: elem.purchase_request_is_center,
                  label: elem.purchase_request_number,
                  value: elem.purchase_request_id,
                })
              }
            } else {
                itemsJoin.push({
                  is_center: elem.purchase_request_is_center,
                  label: elem.purchase_request_number,
                  value: elem.purchase_request_id,
                })
            }
            })
            this.itemsUnfiltered = itemsJoin
            this.items = itemsJoin.filter(item => item.is_center === false)
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Get Data Failed",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
        .catch(e => {
          console.log(e)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Get Settings Failed",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          })
        })
    },
    submitNewPurchaseOrder() {
      this.submitButtonDisabled = true
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      if (this.deliverySchedule !== "") {
        const prIds = []
        this.chosens.map(elem => {
          if (!prIds.includes(elem.pr_id)) {
            prIds.push(elem.pr_id)
          }
        })
        const isValid = this.chosens.every(elem => {
        // Use a regular expression to check if the price is a valid number
        return /^[0-9,.]+$/.test(elem.price)
        })
        if (isValid) {
          if (this.chosenContact.value !== "") {
          if (this.chosens.length > 0) {
        const body = {
          purchase_order_ref_no: this.purchaseOrderRefNo,
          purchase_order_pr_ids: prIds,
          purchase_order_suppplier: this.chosenContact.value,
          purchase_order_shipment_by: this.shipmentBy,
          purchase_order_delivery_address: this.deliveryAddress,
          purchase_order_delivery_schedule: this.deliverySchedule,
          purchase_order_term_of_payment: this.termOfPayment,
          purchase_order_billing_address: this.chosenBranch.value,
          purchase_order_payment_type: this.chosenPaymentType,
          purchase_order_currency_id: this.chosenCurrency,
          purchase_order_type: this.chosenType,
          purchase_order_link: this.purchaseOrderLink,
          purchase_order_purchase_request_id: this.chosenPR.value,
          purchase_order_is_with_tax: this.chosenPpn,
          purchase_order_transport_fee: this.transportFee,
          purchase_order_discount_percentage: this
            .purchaseOrderDiscountPercentage,
          purchase_order_items: JSON.stringify(this.chosens),
          time: moment(),
        }
        axios
          .post(
            `${process.env.VUE_APP_API_URL}transactions/purchase_order/create`,
            body,
            { headers },
          )
          .then(response => {
            if (response.data.success === true) {
              this.submitButtonDisabled = false
              this.chosens = []
              this.chosenContact = ""
              this.shipmentBy = ""
              this.deliveryAddress = ""
              this.deliverySchedule = ""
              this.termOfPayment = ""
              this.chosenBranch = null
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Create Purchase Order Success",
                  icon: "CheckIcon",
                  variant: "success",
                },
              })
              this.$router.replace({
                name: "apps-procurement-purchase-order-list",
              })
            } else {
              this.submitButtonDisabled = false
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Purchase Order Failed",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              })
            }
          })
          .catch(() => {
              this.submitButtonDisabled = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Create Purchase Order Failed",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          })
        } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Please Choose at least one item!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        })
        }
        }
        } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Please fill price fields with numbers only",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        })
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Please Fill All Fields",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        })
      }
    },
    getOptionLabel(option) {
      return (option && option.label) || ""
    },
    putItem(val) {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      axios
        .get(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PURCHASE_REQUESTS_DETAILS}${val.value}`,
          { headers },
        )
        .then(resp => {
          if (resp.data.success === true) {
            const itemsDetails = []
            resp.data.data.map(elem => {
              if (elem.purchase_request_detail_item_qty_accumulation > 0) {
                itemsDetails.push({
                  id: elem.purchase_request_detail_id,
                  label: `${elem.item.item_name} (${elem.item.item_code})`,
                  value: elem.purchase_request_detail_item_id,
                  price: elem.item.item_price,
                  qty: elem.purchase_request_detail_item_qty_accumulation,
                  max: elem.purchase_request_detail_item_qty_accumulation,
                  cheapest: elem.item.item_cheapest_price,
                  currency: elem.item.currency.currency_symbol,
                  pr_id: elem.purchase_request_detail_purchase_request_id,
                })
              }
            })
            this.chosens = itemsDetails
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Get Data Success",
                icon: "CheckIcon",
                variant: "success",
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Get Data Failed",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
    },
    applyItem(val) {
      console.log(val.data)
    },
    getOptionKey(option) {
      return (option && option.value) || ""
    },
    inputSearch(search) {
      console.log(search)
    },
    handleFilesUpload(e) {
      this.file = e.target.files || e.dataTransfer.files
    },
    addReference(val) {
      this.modalReferenceShow = true
      this.itemId = val
    },
    showReference(val) {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      this.itemId = val
      this.modalReferenceHistoryShow = true
      axios
        .get(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_ITEM_LOOKUP}${val}`,
          { headers },
        )
        .then(response => {
          if (response.data.success === true) {
            this.references = response.data.data
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Get References Failed",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
        .catch(e => {
          console.log(e)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Get References Failed",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          })
        })
    },
    dateFormat(value, format = "MM/DD/YYYY") {
      return moment(String(value)).format(format)
    },
    choose(referenceId, itemIdRef, price) {
      const index = this.chosens.findIndex(x => x.value === itemIdRef)
      this.chosens[index].reference_id = referenceId
      this.chosens[index].price = price
      this.modalReferenceHistoryShow = false
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Price Chosen",
          icon: "CheckIcon",
          variant: "success",
        },
      })
    },
    saveNewReference() {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      this.modalReferenceShow = false
      const body = {
        item_reference_item_id: this.itemId,
        item_reference_link: this.itemLink,
        item_reference_price: this.itemPrice,
        item_reference_description: this.itemDescription,
        item_reference_currency_id: this.itemCurrency.value,
        item_reference_supplier: this.itemSupplier,
        time: moment(),
      }
      axios
        .post(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CREATE_ITEM_LOOKUP}`,
          body,
          { headers },
        )
        .then(response => {
          if (response.data.success === true) {
            this.itemId = ""
            this.itemLink = ""
            this.itemPrice = ""
            this.itemDescription = ""
            this.itemCurrency = ""
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "New Reference Added",
                icon: "CheckIcon",
                variant: "success",
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Adding New Reference Failed",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Adding New Reference Failed",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          })
        })
      this.itemReference = ""
      this.itemLink = ""
      this.itemPrice = ""
      this.itemDescription = ""
      this.itemSupplier = ""
      this.modalReferenceShow = false
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Data Saved",
          icon: "CheckIcon",
          variant: "success",
        },
      })
    },
  },
}
</script>
